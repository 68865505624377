<template>
  <div class="news-detail" v-if="detail">
    <div class="container">
      <h1 class="detail-title">{{ getText(detail, 'title') }}</h1>
      <div class="detail-meta" v-if="detail.docUrl">
        <el-button type="primary" size="mini" @click="toPdf(detail.docUrl)">{{ $t('ViewReport') }}</el-button>
      </div>
      <video v-if="detail.resourceType === 'VIDEO'" class="detail-video" controls controlsList="nodownload">
        <source :src="detail.fileUrl" />
      </video>
      <audio v-if="detail.resourceType === 'VOICE'" class="detail-audio" controls controlsList="nodownload" :src="detail.fileUrl"></audio>
      <div class="detail-content" v-html="getText(detail, 'content')"></div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'NewsDetail',
  data() {
    return {
      detail: null,
    }
  },
  watch: {
    $route() {
      this.getNews()
    },
  },
  created() {
    this.getNews()
  },
  methods: {
    // TEXT, VIDEO, VOICE,
    getNews() {
      api.getNews(this.$route.params.id).then((res) => {
        this.detail = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-meta {
  margin-bottom: 15px;
}
.container {
  max-width: 765px;
}
</style>
